<template>
    <div class="w-full relative flex justify-start items-center mt-4">
        <p class="text-sm text-darkPurple font-normal">Group</p>
        <div @mouseenter="onEnter" @mouseleave="onLeave"
            class="absolute top left-11"
        >
            <Icon icon-name="icon-info" class="cursor-pointer" size="xs" />
            <ToolTip v-if="isGroupToolTip" style="width: 250px !important" left="-70px">
                <p class="w-full mx-auto text-xs text-white text-center">
                    The Group helps you to aggregate similar folders.
                    You can create a new group if this folder doesn't match one of your existing groups.
                </p>
            </ToolTip>
        </div>
    </div>
</template>
<script>
export default {
    name: "GroupToolTip",
    components: {
        ToolTip: () => import("../../ToolTip"),
    },
    props: {
        isGroupToolTip: Boolean
    },

    methods: {
        onEnter(){
            this.$emit("on-enter");
        },

        onLeave(){
            this.$emit("on-leave");
        },
    }
};
</script>

<style scoped>
</style>
